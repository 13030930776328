@tailwind base;
@tailwind components;
/* ================
    Custom
=================== */
html {
    @apply font-themeFont antialiased;
}
body {
    @apply overflow-x-hidden bg-gray-50 text-sm text-common-black antialiased;
}
.containers {
    @apply p-6;
}
.container-sm {
    @apply relative mx-auto max-w-[920px];
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    @apply text-interface-600;
}
input:-ms-input-placeholder,
textarea::-ms-input-placeholder {
    /* IE 10+ */
    @apply text-interface-600;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
    /* Firefox 19+ */
    @apply text-interface-600;
}
/* Chrome, Safari, Edge, Opera */
input:-webkit-outer-spin-button,
input:-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
p {
    @apply mb-4;
}
a {
    @apply text-common-black;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    @apply mb-4 font-acumin-bold;
}
h1,
.h1 {
    @apply border-opacity-100 text-3xl;
}
.h2,
h2 {
    @apply text-2xl;
}
.h3,
h3 {
    @apply text-xl;
}
.h4,
h4 {
    @apply text-lg;
}
.h5,
h5 {
    @apply text-base;
}
.h6,
h6 {
    @apply text-sm;
}
ul {
    @apply relative;
}
ol li {
    @apply list-inside list-decimal text-sm;
}
.item-table li {
    @apply relative flex items-center border-b border-interface-200 bg-interface-50 px-4 py-3 even:bg-common-white last-of-type:border-none hover:bg-interface-100;
}
.contract-content ul li {
    @apply mb-3 ml-4 list-outside list-disc text-sm;
}
.contract-content ol li {
    @apply mb-3 ml-4 list-outside;
}
.scrollable {
    @apply overflow-y-auto;
}
.scrollable::-webkit-scrollbar-track,
.fc-scroller::-webkit-scrollbar-track {
    @apply rounded-[50px] bg-common-white;
}
.scrollable::-webkit-scrollbar,
.fc-scroller::-webkit-scrollbar {
    @apply h-[6px] w-[4px] rounded-[50px] bg-common-white;
}
.scrollable::-webkit-scrollbar-thumb,
.fc-scroller::-webkit-scrollbar-thumb {
    @apply rounded-[50px] bg-interface-100;
}
.scrollable.default::-webkit-scrollbar-track {
    @apply bg-interface-50;
}
.scrollable.default::-webkit-scrollbar {
    @apply bg-interface-50;
}
.scrollable.style2::-webkit-scrollbar-track {
    @apply bg-interface-200;
}
.scrollable.style2::-webkit-scrollbar-thumb {
    @apply bg-interface-300;
}
b,
strong {
    font-weight: normal;
    @apply font-acumin-bold;
}
/* ============= notification-scrollbar ============= */
.btnsmall {
    @apply text-sm !important;
}
.btn-i on-small {
    top: -1px;
    @apply relative mr-1 text-xs !important;
}
.auth-title {
    @apply mb-4 font-acumin-bold text-xl uppercase text-interface-900;
}
button.padding0 {
    @apply p-0 !important;
}
button.textCommonBlack {
    @apply text-common-black;
}
/*
    nProgress bar
*/
#nprogress .bar {
    @apply bg-primary-500;
}
#nprogress .spinner {
    @apply hidden;
}
/* =============
    Separator 
=============== */
.separator {
    @apply relative mt-4 mb-4 block h-[1px] w-full bg-gray-200;
}
.auth-heading {
    @apply mb-4 font-acumin-bold text-xl uppercase text-interface-900;
}
.vertical-seprator:after {
    content: '';
    @apply absolute right-[-22px] top-0 h-full w-[1px] bg-interface-200;
}
.mobile-horizontal-seprator:after {
    @apply mobile:right-0 mobile:-bottom-[30px] mobile:top-auto mobile:h-[1px] mobile:w-full;
}
.accordian-active .icon-caret-down {
    @apply inline-block rotate-180;
}
/* =============
    Sticky TOp Header
=============== */
.sticky-top-header {
    @apply sticky top-0 right-0 z-[15] mb-6 -ml-[24px] -mr-[24px] h-[56px] w-auto min-w-full  items-center justify-center bg-common-white px-6 shadow;
}
.sticky-top-header .inner {
    @apply flex h-[56px] w-full items-center py-[10px] ipad:fixed ipad:top-2 ipad:left-0 ipad:h-auto ipad:flex-col ipad:bg-common-white ipad:p-0;
}
.sticky-top-header .create-new-quote.inner {
    @apply flex h-[56px] w-full items-center justify-center ipad:fixed ipad:left-0 ipad:flex-col ipad:bg-common-white ipad:py-[15px];
}
.sticky-top-header .workspace-header-inner {
    @apply ipad:top-0 ipad:block ipad:py-[15px] ipad:text-center;
}
/* =============
Right panel
=============== */
.right-panel {
    @apply ml-auto w-[calc(100%-260px)] px-6 ipad:w-full;
}
/* =============
    Separator with text
=============== */
.separator-with-text {
    @apply relative mb-5 flex h-5 w-full justify-center;
}
.separator-with-text::after {
    content: '';
    height: 1px;
    @apply absolute left-0 right-0 top-2/4 w-full bg-interface-200;
}
.separator-with-text span {
    @apply relative z-10 px-2;
}
.custom-underline {
    @apply absolute left-0 right-0 bottom-0.5 h-[1px] w-full bg-primary-500;
}
.card {
    @apply overflow-hidden rounded-lg border border-interface-200 bg-common-white;
}
.card-head {
    @apply border-b border-interface-200 px-4 py-4;
}
.card-body {
    @apply p-4;
}
/* =========================
    Custom Duration picker 
============================ */
.react-time-input-picker {
    color: #212121;
    border-radius: 2px;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    margin: 0;
    align-items: center;
    height: 100%;
    width: auto;
    justify-content: center;
    position: relative;
}
.react-time-input-picker input::-webkit-outer-spin-button,
.react-time-input-picker input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.react-time-input-picker input[type='number'] {
    -moz-appearance: textfield;
}
.react-time-input-picker input {
    position: relative;
    margin: 0 2px;
    padding: 0;
    border-radius: 2px;
    background: transparent;
    width: max-content;
    min-width: 38px;
    text-align: center;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}
.react-time-input-picker input::selection {
    background: transparent;
}
.react-time-input-picker input::placeholder {
    opacity: 1;
    font-weight: inherit;
    font-size: 18px;
}
.inputWrapper {
    position: relative;
    align-items: center;
    height: 100%;
    max-width: auto;
    display: flex;
}
.react-time-input-picker-wrapper {
    height: 37px;
    padding: 4px 0;
    margin: 5px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: max-content;
    justify-content: center;
}
.react-time-input-picker-wrapper * {
    box-sizing: border-box;
}
.react-time-input-picker.is-disabled,
.react-time-input-picker.is-disabled * {
    cursor: not-allowed !important;
}
.inputWrapper:nth-child(2) {
    max-width: auto;
}
.inputWrapper:nth-child(1)::after {
    content: ':';
    display: flex;
    align-items: center;
    font-size: inherit;
    font-weight: inherit;
    margin-bottom: 0;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    top: -1px;
}
/* 
.react-time-input-picker input:focus {
    caret-color: transparent;
    background-color: #dadada;
} */
.react-time-input-picker span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: inherit;
    margin-bottom: 3px;
}
.react-time-input-picker input[type='text'] {
    min-width: unset;
    max-width: 45px;
    height: 100%;
}
.react-time-input-picker .arrow-down {
    margin-right: 7px;
    cursor: pointer;
    height: 100%;
    margin-bottom: 0;
}
.react-time-input-picker .input-time-mobile input[type='time'] {
    background: transparent;
}
.inputWrapper__dropdown {
    position: absolute;
    left: 0;
    bottom: -205px;
    border-radius: 5px;
    height: 200px;
    overflow: auto;
    min-width: 150%;
    width: 150%;
    border: 1px solid #eeedee;
    box-shadow: 0 0 6px 0.2px #a0a0a0;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 5px 0;
}
.manuallyDisplayDropdown .inputWrapper__dropdown {
    min-width: 110%;
    width: 110%;
}
.inputWrapper__dropdown.amPm {
    width: 110%;
    min-width: 110%;
    bottom: -81px;
    overflow-y: hidden;
    height: 76px;
}
.inputWrapper__dropdown span {
    min-height: 30px;
    color: #434343;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    cursor: pointer;
    padding: 0 10px;
    font-weight: 500;
    margin-bottom: 0;
    margin-bottom: 2px;
}
.btn-add-collab span {
    @apply capitalize;
}
.booking-action button span {
    @apply capitalize;
}
.label-lg label span {
    @apply font-acumin-bold text-lg text-common-black;
}
.inputWrapper__dropdown span.is-active,
.inputWrapper__dropdown span:hover {
    background: #dadada;
}
.inputWrapper__dropdown::-webkit-scrollbar {
    width: 8px;
    height: 6px;
}
.inputWrapper__dropdown::-webkit-scrollbar-track {
    background-color: #eeedee;
    border-radius: 4px;
}
.inputWrapper__dropdown::-webkit-scrollbar-thumb {
    background: #a0a0a0;
    border-radius: 4px;
}
.inputWrapper__dropdown::-webkit-scrollbar-thumb:hover {
    background: #434343;
}
.fullTime__wrapper {
    position: absolute;
    width: 110%;
    left: -5%;
    right: 5%;
    bottom: 0px;
}
.fullTime__wrapper .inputWrapper__dropdown.fullTime {
    left: 0;
    right: 0;
    width: 100%;
    min-width: 100%;
}
.associate-names:last-child span {
    @apply hidden;
}
.fullTime__wrapper .inputWrapper__dropdown.fullTime span {
    font-weight: 500;
    font-size: 16px;
}
.fc-col-header {
    @apply min-w-full;
}
.fc .fc-daygrid-day.fc-day-today,
.fc .fc-daygrid-day.fc-day-today > div {
    @apply bg-danger-200 !important;
}
.fullTime__wrapper .inputWrapper__dropdown.fullTime div {
    height: 100%;
    display: inline-flex;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 2px;
    margin-left: 10px;
    align-items: center;
}
.fc .fc-scrollgrid-section-body table,
.fc .fc-scrollgrid-section-footer table {
    @apply min-w-full;
}
/* =============
    Datepicker
=============== */
.custom-date-time .react-datepicker {
    @apply rounded-lg border-interface-100 bg-common-white shadow;
}
.react-datepicker__month-container {
    @apply relative p-1.5 pt-[80px];
}
.custom-date-time .react-datepicker__header {
    @apply pointer-events-none absolute top-0 left-0 right-0 mx-auto h-full w-full border-none bg-transparent pt-3;
}
.custom-date-time
    .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown {
    @apply pointer-events-auto;
}
.custom-date-time .react-datepicker__year-read-view--down-arrow,
.custom-date-time .react-datepicker__month-read-view--down-arrow,
.custom-date-time .react-datepicker__month-year-read-view--down-arrow,
.custom-date-time .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    @apply border-common-black hover:border-common-black;
}
.custom-date-time
    .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
.custom-date-time
    .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    @apply border-b-common-white;
}
.custom-date-time
    .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    @apply border-b-interface-100;
}
.react-datepicker__current-month {
    @apply absolute left-4;
}
.react-datepicker__day-names {
    @apply relative mt-6 font-acumin-bold after:absolute after:bottom-0 after:left-0 after:right-0 after:mx-auto after:h-[1px] after:w-[calc(100%-10px)] after:bg-interface-100 after:content-[''];
}
.react-datepicker__navigation.react-datepicker__navigation--previous,
button.react-datepicker__navigation.react-datepicker__navigation--next {
    @apply top-2;
}
.react-datepicker__navigation.react-datepicker__navigation--previous {
    @apply left-auto right-8;
}
.custom-date-time .react-datepicker__day--keyboard-selected,
.custom-date-time .react-datepicker__month-text--keyboard-selected,
.custom-date-time .react-datepicker__quarter-text--keyboard-selected,
.custom-date-time .react-datepicker__year-text--keyboard-selected,
.react-datepicker__day.react-datepicker__day--selected {
    @apply bg-primary-500 hover:bg-primary-500;
}
.custom-date-time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    @apply bg-primary-500 hover:bg-primary-500;
}
.custom-date-time input,
.custom-date-time .react-datepicker-wrapper,
.custom-date-time .react-datepicker__input-container {
    @apply absolute top-0 left-0 right-0 h-full w-full rounded-md border-none bg-none px-3 outline-none;
}
.time-picker-input .custom-date-time input.react-datepicker-time__input {
    cursor: text;
    letter-spacing: -0.6px;
    @apply absolute top-[-42px] left-[-15px] ml-[14px] h-auto
        w-[80px] rounded-md border-none bg-common-white px-3 font-themeFont text-sm text-common-black outline-none !important;
}
.time-picker-input
    .custom-date-time
    input.react-datepicker-time__input::-webkit-calendar-picker-indicator {
    @apply hidden;
}
.time-picker-input .react-datepicker__input-time-container {
    @apply m-0 h-0 text-transparent;
}
.custom-date-time .react-datepicker__day-name,
.custom-date-time .react-datepicker__day,
.custom-date-time .react-datepicker__time-name {
    @apply h-9 w-9 py-[5px];
}
.custom-date-time .icon-section {
    @apply absolute top-0 right-0 flex h-full items-center justify-center px-3.5;
}
.custom-date-time i {
    @apply text-base text-interface-600;
}
.custom-date-time .react-datepicker__month-read-view {
    @apply absolute left-0;
}
.custom-date-time .react-datepicker__year-read-view {
    @apply pointer-events-auto absolute top-[5px] left-0 h-9 w-1/2  bg-common-black opacity-0;
}
.custom-date-time
    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll,
.custom-date-time
    .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--scroll {
    @apply absolute top-0 left-0 right-0 mx-auto h-full w-full overflow-hidden;
}
.custom-date-time .react-datepicker__year-dropdown {
    @apply pointer-events-auto absolute  left-0  top-0 flex h-full  w-full flex-row-reverse flex-wrap-reverse gap-3 border-none  bg-common-white py-[54px] px-[44px];
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option {
    @apply left-0 flex w-[25%] items-center justify-around overflow-hidden border-none bg-common-white p-4 text-center;
}
.year-range .react-datepicker__year-dropdown .react-datepicker__year-option {
    width: auto;
}
.year-range .react-datepicker__year-dropdown {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:first-of-type {
    background-position: center;
    background-size: 28px auto;
    @apply absolute left-0 right-0 top-2 h-[40px] w-[94%] bg-interface-100 bg-no-repeat;
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:last-of-type {
    background-position: center;
    background-size: 28px auto;
    @apply absolute left-0 right-0 bottom-2 h-[40px] w-[94%] bg-interface-100 bg-no-repeat;
}
/* DOB Datepicker customization */
.custom-date-time.dobDatePicker .react-datepicker__navigation {
    @apply hidden;
}
/* DOB Datepicker customization end*/
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:first-of-type
    .react-datepicker__navigation {
    @apply top-0 bg-[url('/up-arrow.svg')] bg-center bg-no-repeat pr-[76PX];
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:last-of-type
    .react-datepicker__navigation {
    @apply top-auto bottom-0 bg-[url('/down-arrow.svg')] bg-center bg-no-repeat pr-[76PX];
}
.custom-date-time
    .react-datepicker__year-option.react-datepicker__year-option--selected_year {
    @apply rounded-md bg-primary-500 font-acumin-bold text-common-white;
}
.custom-date-time
    .react-datepicker__year-option.react-datepicker__year-option--selected_year:first-of-type,
.custom-date-time
    .react-datepicker__year-option.react-datepicker__year-option--selected_year:last-of-type {
    @apply text-common-black;
}
.custom-date-time .react-datepicker__year-option--selected {
    @apply hidden;
}
.react-datepicker__day.react-datepicker__day--outside-month {
    @apply text-interface-300;
}
.react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--outside-month {
    @apply text-common-white;
}
.rc-time-picker input {
    @apply text-sm;
}
/*
    Time picker updation
*/
.custom-date-time.customize-data-time-picker
    .react-datepicker__input-time-container {
    @apply m-0 border-t border-interface-200 px-5 py-6 pt-4;
}
.custom-date-time.customize-data-time-picker .react-datepicker-time__caption {
    @apply block w-full text-[0px];
}
.custom-date-time.customize-data-time-picker
    .react-datepicker-time__caption:after {
    content: 'Outbound time';
    @apply relative text-sm font-semibold;
}
.custom-date-time.customize-data-time-picker
    .react-datepicker-time__input-container {
    @apply m-0 block w-full;
}
.custom-date-time.customize-data-time-picker .react-datepicker-time__input {
    @apply m-0 h-[42px] w-full  rounded-md border border-interface-300 p-0 px-4;
}
.custom-date-time.customize-data-time-picker
    input.react-datepicker-time__input {
    @apply relative m-0 w-full bg-transparent p-0;
}
.custom-date-time.customize-data-time-picker
    input.react-datepicker-time__input:after {
    content: '';
    background-color: #fff;
    @apply pointer-events-none absolute right-0 top-[9px] h-[22px] w-[22px] bg-[url('/clock.svg')] bg-contain bg-center   bg-no-repeat content-[''];
}
.custom-date-time .react-datepicker-wrapper,
.custom-date-time .react-datepicker__input-container {
    @apply cursor-pointer;
}
/* DOB Datepicker customization */
.custom-date-time.dobDatePicker .react-datepicker__month-container {
    @apply pt-0;
}
.custom-date-time.dobDatePicker .react-datepicker__header {
    @apply pointer-events-auto relative top-0;
}
.custom-date-time.dobDatePicker .react-datepicker__current-month {
    @apply relative left-0 top-1 w-auto text-left;
}
.custom-date-time.dobDatePicker .react-datepicker__header__dropdown {
    @apply absolute right-[5px] top-0 pt-3;
}
.custom-date-time.dobDatePicker .react-datepicker__month-dropdown-container,
.custom-date-time.dobDatePicker .react-datepicker__year-dropdown-container {
    @apply m-0 border border-interface-300 px-[2px] py-[2px] text-[15px] outline-none focus:outline-none;
}
.custom-date-time.dobDatePicker
    .react-datepicker__month-dropdown-container
    select,
.custom-date-time.dobDatePicker
    .react-datepicker__year-dropdown-container
    select {
    @apply focus-visible:outline-none;
}
.custom-date-time.dobDatePicker.react-datepicker__day-names {
    @apply mt-[14px];
}
/* DOB Datepicker customization end*/
.uploadField input {
    @apply absolute top-0 left-0 block h-full w-full opacity-0 !important;
}
.default-react-select.dark input {
    @apply text-common-white !important;
}
/* Flight details date time */
.modified-flight-date-time {
    @apply relative inline-block cursor-pointer underline;
}
.modified-flight-date-time .flight-time {
    @apply pointer-events-none relative mr-1 inline-block cursor-none italic text-common-black;
}
.modified-flight-date-time-no-cursor {
    @apply relative inline-block;
}
.modified-flight-date-time .picker-overlay {
    @apply absolute top-0 left-0 right-0 mx-auto w-full cursor-pointer;
}
.modified-flight-date-time .picker-overlay .date-picker-form-control {
    @apply h-[30px] cursor-pointer border-none;
}
.modified-flight-date-time .picker-overlay .icon-section {
    @apply hidden;
}
.modified-flight-date-time .picker-overlay .react-datepicker__input-container {
    @apply cursor-pointer;
}
.modified-flight-date-time
    .picker-overlay
    .date-picker-form-control
    .react-datepicker-wrapper {
    @apply opacity-0;
}
.modified-flight-date-time .picker-overlay .react-datepicker-popper {
    @apply pt-0;
}
.modified-flight-date-time .picker-overlay .custom-date-time input {
    @apply cursor-pointer;
}
/*
    With time picker
*/
.date-time-picker-wrapper .custom-date-time .react-datepicker {
    @apply w-[420px] mobile:w-[310px];
}
.date-time-picker-wrapper .custom-date-time .react-datepicker__time-container {
    @apply w-[100px] border-interface-100 mobile:w-[310px];
}
.date-time-picker-wrapper
    .custom-date-time
    .react-datepicker__header.react-datepicker__header--time {
    @apply relative top-0 left-0 shadow;
}
.date-time-picker-wrapper
    .custom-date-time
    .react-datepicker__navigation.react-datepicker__navigation--previous {
    @apply right-[115px];
}
.date-time-picker-wrapper
    .custom-date-time
    .react-datepicker__time-container
    .react-datepicker__time {
    @apply mt-[2px];
}
.date-time-picker-wrapper
    .custom-date-time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    @apply w-full;
}
.date-time-picker-wrapper
    .custom-date-time
    button.react-datepicker__navigation.react-datepicker__navigation--previous {
    right: 140px;
}
.date-time-picker-wrapper
    .custom-date-time
    button.react-datepicker__navigation.react-datepicker__navigation--next {
    right: 106px;
}
.date-time-picker-wrapper
    .custom-date-time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    @apply text-common-black hover:bg-primary-500 hover:text-common-white;
}
.date-time-picker-wrapper
    .custom-date-time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
    @apply text-common-white;
}
.date-time-picker-wrapper
    .custom-date-time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list::-webkit-scrollbar-track {
    @apply rounded-[50px] bg-common-white;
}
.date-time-picker-wrapper
    .custom-date-time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list::-webkit-scrollbar {
    @apply h-[6px] w-[4px] rounded-[50px] bg-common-white;
}
.date-time-picker-wrapper
    .custom-date-time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
    @apply rounded-[50px] bg-interface-100;
}
.date-time-picker-wrapper
    .custom-date-time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list.default::-webkit-scrollbar-track {
    @apply bg-interface-50;
}
.date-time-picker-wrapper
    .custom-date-time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list.default::-webkit-scrollbar {
    @apply bg-interface-50;
}
/* .modified-flight-date-time .custom-date-time .date-picker-form-control {
    @apply h-[30px] cursor-pointer border-none bg-transparent shadow-none;
}
.modified-flight-date-time .custom-date-time .date-picker-form-control input {
    @apply opacity-0 cursor-pointer;
}
.modified-flight-date-time
    .custom-date-time
    .date-picker-form-control
    .icon-section {
    @apply opacity-0;
}
.modified-flight-date-time
    .custom-date-time
    .date-picker-form-control
    .react-datepicker-popper[data-placement^='bottom'] {
    @apply pt-0;
} */
.disabled-timepicker .rdtPicker.rdtPicker .rdtTimeToggle {
    @apply hidden;
}
input.left-icon {
    @apply pl-9;
}
.custom-selectbox {
    @apply relative;
}
.custom-selectbox i {
    @apply pointer-events-none absolute top-0 bottom-0 right-4 m-auto flex items-center text-xs text-interface-600;
}
/* =============
    Table
=============== */
.rdt_Table {
    @apply w-full rounded-lg border border-none font-themeFont text-xs;
}
.rdt_Table .rdt_TableCell {
    @apply break-normal text-common-black;
}
.rdt_Table .rdt_TableHeadRow {
    @apply min-h-[35px];
}
.table-placeholder .rdt_TableRow {
    @apply flex h-[45px] w-full animate-pulse items-center justify-center;
}
.base-table .rdt_Table .rdt_TableRow {
    @apply bg-common-white even:bg-interface-50 hover:bg-interface-100;
}
.base-table .rdt_Table .rdt_TableRow,
.MjKnz:not(:last-of-type),
.base-table .rdt_Table:not(:last-of-type) {
    @apply border-none border-b-transparent;
}
.btn-tag i {
    @apply relative mr-2 text-base mobile:mr-1 mobile:text-sm;
}
.tabList button i {
    @apply mr-2 text-base mobile:hidden;
}
.rdt_Table .actions i {
    @apply mr-1 cursor-pointer p-2 text-lg text-primary-500;
}
.rdt_Table .actions .icon-edit {
    @apply p-2 text-[20px];
}
.rdt_Table .actions .icon-users-remove {
    @apply mr-1 mt-[2px] cursor-pointer p-2 font-acumin-bold text-[24px] text-primary-500;
}
.content-placeholders-animation.pipeline-content-placeholders-animation i {
    @apply hidden;
}
.passenger-table .rdt_TableCol[data-column-id='1'],
.passenger-table .rdt_TableCol[data-column-id='2'] {
    @apply mobile:bg-interface-100;
}
.passenger-table .rdt_TableCell[data-column-id='1'],
.passenger-table .rdt_TableCell[data-column-id='2'] {
    @apply bg-interface-100 mobile:bg-transparent;
}
.passenger-table .rdt_TableCol[data-column-id='1'],
.passenger-table .rdt_TableCell[data-column-id='1'] {
    @apply sticky left-0 z-[9] mobile:static mobile:z-0;
}
.passenger-table .rdt_TableCol[data-column-id='2'],
.passenger-table .rdt_TableCell[data-column-id='2'] {
    @apply sticky left-12 z-[9] border-r border-interface-200 mobile:static mobile:z-0 mobile:border-none;
}
/*
    customize Radio
*/
.customize-radio {
    @apply relative mb-2 cursor-pointer pl-7;
}
.customize-radio .circle {
    @apply absolute left-0 top-0.5 block h-[16px] w-[16px] rounded-full border border-interface-300 text-center;
}
.customize-radio .circle i {
    print-color-adjust: exact;
    @apply relative top-1 left-[0.2px] hidden text-[8px] text-common-white;
}
.customize-radio .label-text {
    @apply font-themeFont text-sm;
}
.customize-radio.active .circle {
    print-color-adjust: exact;
    @apply border-primary-500 bg-primary-500;
}
.table-placeholder.table-placeholder i {
    @apply mt-[16px] inline-block h-4 w-4 bg-interface-200 text-opacity-0;
}
.active.accordian-header i {
    @apply inline-block rotate-180;
}
.rdt_Table .actions.passenger i {
    @apply ml-4 text-xs;
}
.customize-radio.active .circle i {
    @apply block;
}
.rdt_Table .actions.gray i {
    @apply text-interface-400;
}
.table-placeholder .rdt_TableHeadRow {
    @apply flex w-full items-center bg-body-bg;
}
.hideHeader .rdt_Table .rdt_TableHeadRow {
    @apply hidden;
}
.base-table.aircraft-option-table .rdt_Table .rdt_TableRow {
    @apply border-b border-solid border-interface-200 bg-interface-50 py-3 even:bg-common-white last-of-type:border-none;
}
.sc-dmRaPn,
.overflow-x-scroll {
    overflow-x: auto;
}
.sc-dmRaPn::-webkit-scrollbar-track,
.overflow-x-scroll::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 51px;
}
.sc-dmRaPn::-webkit-scrollbar,
.overflow-x-scroll::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    background-color: #fff;
    border-radius: 51px;
}
.sc-dmRaPn::-webkit-scrollbar-thumb,
.overflow-x-scroll::-webkit-scrollbar-thumb {
    background-color: #d4d4d4;
    border-radius: 51px;
}
.eUeqdG {
    @apply text-xs;
}
.table-lg .eUeqdG {
    @apply text-sm;
}
.table-lg .rdt_Table .rdt_TableHeadRow {
    min-height: 40px;
}
/* =============
    Dashboard Table
=============== */
.dashboard-table .rdt_Table .rdt_TableBody .rdt_TableRow.rdt_TableRow {
    @apply relative min-h-[44px] bg-transparent text-common-black;
}
.dashboard-table .rdt_Table .rdt_TableBody .rdt_TableRow.rdt_TableRow:after {
    content: '';
    @apply absolute bottom-0 left-0 right-0 mx-auto w-[calc(100%-48px)] border-b border-dashed border-interface-200 bg-transparent;
}
.dashboard-table .rdt_Table .rdt_TableHeadRow {
    @apply min-h-[28px] pb-[0.5px];
}
.dashboard-table
    .rdt_Table
    .rdt_TableBody
    .rdt_TableRow.rdt_TableRow:last-child:after {
    @apply hidden;
}
.dashboard-table .rdt_TableCell {
    @apply mobile:min-w-[160px];
}
/* =============
    Table Placeholder
=============== */
.table-placeholder {
    @apply font-redacted;
}
.table-placeholder .rdt_Table .rdt_TableBody .rdt_TableRow.rdt_TableRow {
    @apply font-redacted text-interface-200;
}
.table-placeholder .rdt_TableHead {
    @apply flex h-full w-full flex-col items-center justify-center;
}
.table-placeholder .rdt_TableCol {
    @apply w-full min-w-[135px] max-w-full px-[24px];
}
.table-placeholder .rdt_TableBody {
    @apply flex flex-col;
}
.table-placeholder .rdt_TableCell {
    @apply w-full min-w-[135px] max-w-full px-[24px];
}
.table-placeholder.table-placeholder .rdt_TableCell div {
    @apply whitespace-nowrap;
}
.table-placeholder
    .rdt_Table
    .rdt_TableHead
    .rdt_TableHeadRow
    .rdt_TableCol.rdt_TableCol,
.table-placeholder a,
.table-placeholder .status-loader {
    @apply font-redacted text-interface-200;
}
.table-placeholder .status-loader.status-loader {
    @apply bg-interface-200 text-opacity-0;
}
.table-placeholder .rdt_Table .rdt_TableCell {
    @apply text-interface-200;
}
.text-placeholders,
.content-placeholders-animation,
.content-placeholders-animation .status-loader.status-loader,
.text-placeholders .status-loader.status-loader {
    @apply animate-pulse font-redacted text-interface-300;
}
/* =============
    Tabs
=============== */
.tabList {
    @apply flex;
}
.tabList button {
    @apply relative mr-6 flex h-12 items-center justify-center border-none px-3 py-3 text-sm text-interface-600 outline-none md:mr-1 md:px-[6px] ipad:mr-3 mobile:mr-1;
}
.tabTimeLine button {
    @apply h-auto p-0;
}
.radius-tab button {
    @apply mr-0 h-[38px] w-full border-r border-solid border-interface-300 bg-interface-200 text-sm text-interface-700;
}
.dashboard-tabs .tabList button {
    @apply mobile:mr-2;
}
.tabList button[aria-selected='true'] {
    @apply font-acumin-bold text-common-black;
}
.tabList button[aria-selected='true']:after {
    content: '';
    @apply absolute bottom-0 h-[1px] w-full bg-common-black;
}
.tabTimeLine button:last-child {
    @apply mr-0;
}
.tabTimeLine button[aria-selected='true'] {
    @apply font-themeFont text-primary-500;
}
.tabTimeLine button[aria-selected='true']:after {
    @apply bg-primary-500;
}
.radius-tab {
    @apply overflow-hidden rounded-md border border-interface-300;
}
.radius-tab button:last-child {
    @apply border-r-0;
}
.radius-tab button[aria-selected='true']::after {
    @apply hidden;
}
.radius-tab button[aria-selected='true'] {
    @apply bg-common-white;
}
.radius-tab.tab-lg button {
    @apply h-[41px] px-[14px] text-base;
}
/* =============
    Card
=============== */
.default-card {
    @apply overflow-hidden rounded-lg border-[0.5px] border-interface-200 bg-common-white p-4;
}
.default-card-action-pos {
    @apply absolute right-4 top-4 cursor-pointer text-lg text-interface-600;
}
.default-card-action-icon {
    @apply relative cursor-pointer p-3 text-lg text-interface-600;
}
.dashboard-block-heading {
    @apply mb-[10px] text-sm uppercase;
}
/* =============
    Child inner Left and right section
=============== */
.child-inner-wrapper {
    @apply flex w-full;
}
.child-inner-left {
    @apply mr-6 w-[384px] flex-shrink-0;
}
.child-inner-right {
    @apply ml-auto w-full;
}
.btn-tag {
    @apply relative flex h-[41px] cursor-pointer items-center justify-center rounded-md border border-interface-500 bg-interface-600 py-2 px-4 text-interface-200 ipad:w-full tablet:mb-4 tablet:justify-start tablet:last-of-type:mb-0 mobile:px-2;
}
.bookings-rows .row {
    @apply flex w-full border-b border-interface-200 py-4 text-base first-of-type:pt-0 last-of-type:mb-0 last-of-type:border-none last-of-type:pb-0;
    @apply flex w-full border-b border-interface-200 py-4 text-base first-of-type:pt-0 last-of-type:mb-0 last-of-type:border-none last-of-type:pb-0 mobile:block;
}
/* =============
status
=============== */
.status-danger {
    @apply border-primary-500;
    background-color: #fff1f2;
}
/* =============
items table
=============== */
.item-table {
    @apply overflow-hidden rounded-lg border border-interface-200;
}
.contract-table {
    @apply overflow-hidden rounded-lg border border-interface-200;
}
.contract-table .list {
    @apply table h-[44px] w-full border-b border-interface-200 bg-interface-50 px-4 py-3 align-middle even:bg-common-white last-of-type:border-none;
}
.download-pdf-template .contract-table .list {
    @apply py-0 pt-1;
}
/* =============
items table
=============== */
.select-duration-timepicker ul li {
    @apply cursor-pointer py-1 pl-3 text-interface-600;
}
.item-table.aircraft-price-list li {
    @apply bg-interface-50 py-[6px] last:bg-interface-100;
}
.custom-item-table.item-table li {
    @apply mb-6 rounded-md border border-interface-200 last-of-type:border-solid !important;
}
.item-table.invoice-table li:last-child,
.contract-table.invoice-table .list:last-child {
    print-color-adjust: exact;
    @apply bg-common-black text-common-white;
}
.item-table.current-invoice-table li:nth-last-child(1) {
    print-color-adjust: exact;
    @apply bg-interface-200 text-common-black;
}
.item-table.current-invoice-table li.active {
    print-color-adjust: exact;
    @apply bg-common-black text-common-white;
}
print-color-adjust: exact;
.custom-item-table.item-table {
    @apply border-none;
}
.bLyqNT.bLyqNT,
.eUeqdG.eUeqdG,
.bMgaAx.bMgaAx div:first-child,
.dBbhDz.dBbhDz div:first-child {
    @apply overflow-visible;
}
.passenger-table .bLyqNT.bLyqNT,
.passenger-table .eUeqdG.eUeqdG,
.passenger-table .bMgaAx.bMgaAx div:first-child,
.passenger-table .dBbhDz.dBbhDz div:first-child {
    @apply whitespace-pre-wrap;
}
.btn-modify.btn-modify {
    @apply text-xs;
}
.rdt_Table .rdt_TableCell label {
    @apply min-h-[20px];
}
/* =============
label
=============== */
.label {
    @apply mb-2 font-acumin-bold text-sm text-common-black;
}
/* Table sorting */
.rdt_Table .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable span {
    @apply ml-2;
}
/* .no-elepsis-data .base-table .rdt_TableHeadRow .rdt_TableCol {
    @apply mobile:min-w-[auto] mobile:max-w-[auto];
} */
/*
    React Select 
*/
.default-react-select {
    @apply relative;
}
.default-react-select .search-icon {
    @apply absolute left-0 top-0 flex h-full items-center justify-center p-3.5;
}
/* .default-react-select .css-8mmkcg {
    display: none;
} */
.default-react-select .icon-search {
    @apply text-base text-interface-600;
}
.default-react-select.dark .icon-search {
    @apply text-interface-200;
}
.default-react-select .css-b62m3t-container,
.default-react-select .css-1s2u09g-control,
.default-react-select .css-1pahdxg-control,
.default-react-select .css-b62m3t-container.css-b62m3t-container {
    @apply h-[42px] w-full rounded-md border border-interface-300 bg-common-white pr-0 pl-8 text-sm text-common-black placeholder:text-interface-600 hover:border-interface-300 focus:border-interface-300 focus:shadow-none focus:outline-none !important;
}
.default-react-select .css-b62m3t-container {
    @apply focus-within:border-interface-600 focus:border-interface-600;
}
.creatableSelect.multiSelect .css-b62m3t-container.css-b62m3t-container,
.creatableSelect.multiSelect .css-1s2u09g-control,
.creatableSelect.multiSelect .css-1pahdxg-control {
    @apply scrollable h-[42px] w-full overflow-y-auto rounded-md border-interface-300 text-common-black shadow-none placeholder:text-interface-600 hover:border-interface-300 focus:border-interface-300 focus:shadow-none focus:outline-none !important;
}
.default-react-select.dark .css-b62m3t-container,
.default-react-select.dark .css-1s2u09g-control,
.default-react-select.dark .css-1pahdxg-control,
.default-react-select.dark .css-b62m3t-container.css-b62m3t-container {
    @apply border-interface-500 bg-interface-600 !important;
}
.announcement-select .default-react-select .css-b62m3t-container,
.announcement-select .default-react-select .css-1s2u09g-control,
.announcement-select .default-react-select .css-1pahdxg-control,
.announcement-select
    .default-react-select
    .css-b62m3t-container.css-b62m3t-container {
    @apply h-auto max-h-[100px] !important;
}
.payment-terminal
    .creatableSelect.multiSelect
    .css-b62m3t-container.css-b62m3t-container,
.payment-terminal .creatableSelect.multiSelect .css-1s2u09g-control,
.payment-terminal .creatableSelect.multiSelect .css-1pahdxg-control,
.resizeable-field
    .creatableSelect.multiSelect
    .css-b62m3t-container.css-b62m3t-container,
.resizeable-field .creatableSelect.multiSelect .css-1s2u09g-control,
.resizeable-field .creatableSelect.multiSelect .css-1pahdxg-control {
    @apply h-auto w-full overflow-y-auto rounded-md border-interface-300 text-common-black shadow-none placeholder:text-interface-600 hover:border-interface-300 focus:border-interface-300 focus:shadow-none focus:outline-none !important;
}
.default-react-select .css-hxdyr0-control,
.default-react-select .css-jtl9jd-control {
    @apply scrollable h-[38px] w-full overflow-y-auto;
}
.resizeable-field
    .default-react-select.multiSelect
    .css-b62m3t-container.css-b62m3t-container,
.resizeable-field .default-react-select .css-hxdyr0-control,
.resizeable-field .default-react-select .css-jtl9jd-control {
    @apply h-auto overflow-y-visible !important;
}
.payment-terminal
    .creatableSelect.multiSelect
    .css-b62m3t-container.css-b62m3t-container
    .css-1hb7zxy-IndicatorsContainer,
.payment-terminal
    .creatableSelect.multiSelect
    .css-b62m3t-container.css-b62m3t-container
    .css-1wy0on6,
.resizeable-field
    .creatableSelect.multiSelect
    .css-b62m3t-container.css-b62m3t-container
    .css-1hb7zxy-IndicatorsContainer,
.resizeable-field
    .creatableSelect.multiSelect
    .css-b62m3t-container.css-b62m3t-container
    .css-1wy0on6 {
    @apply items-start !important;
}
.creatable-with-search
    .creatableSelect.multiSelect
    .css-b62m3t-container.css-b62m3t-container,
.creatable-with-search .creatableSelect.multiSelect .css-1s2u09g-control,
.creatable-with-search .creatableSelect.multiSelect .css-1pahdxg-control {
    @apply h-auto w-full overflow-y-visible rounded-md border-interface-300 text-common-black shadow-none placeholder:text-interface-600 hover:border-interface-300 focus:border-interface-300 focus:shadow-none focus:outline-none !important;
}
.creatableSelectOptions.creatableSelectOptions
    .creatableSelect.multiSelect
    .css-b62m3t-container.css-b62m3t-container,
.creatableSelectOptions.creatableSelectOptions
    .creatableSelect.multiSelect
    .css-1s2u09g-control,
.creatableSelectOptions.creatableSelectOptions
    .creatableSelect.multiSelect
    .css-1pahdxg-control {
    @apply scrollable h-[42px] w-full overflow-y-visible rounded-md border-interface-300 text-common-black shadow-none placeholder:text-interface-600 hover:border-interface-300 focus:border-interface-300 focus:shadow-none focus:outline-none !important;
}
.resizeable-field
    .creatableSelectOptions.creatableSelectOptions
    .creatableSelect.multiSelect
    .css-b62m3t-container.css-b62m3t-container,
.resizeable-field
    .creatableSelectOptions.creatableSelectOptions
    .creatableSelect.multiSelect
    .css-1s2u09g-control,
.resizeable-field
    .creatableSelectOptions.creatableSelectOptions
    .creatableSelect.multiSelect
    .css-1pahdxg-control {
    @apply h-auto !important;
}
.creatableSelect.multiSelect .css-14el2xx-placeholder {
    @apply text-interface-600;
}
.default-react-select.dark .css-hxdyr0-control,
.default-react-select.dark .css-jtl9jd-control {
    @apply bg-transparent;
}
.announcement-select .default-react-select .css-hxdyr0-control {
    @apply h-auto max-h-[90px];
}
.default-react-select.isFormcontrolError .isHasError,
.default-react-select.dark.isFormcontrolError .isHasError {
    @apply border-danger-600 hover:border-danger-600 !important;
}
.default-react-select.dark .css-qc6sy-singleValue {
    @apply text-common-white;
}
.default-react-select .css-26l3qy-menu,
.creatable-with-search .css-26l3qy-menu {
    @apply left-0 z-50 w-full overflow-hidden rounded-lg border-[0.5px] pt-0 shadow;
}
.default-react-select .css-4ljt47-MenuList {
    @apply p-0;
}
.default-react-select .css-4ljt47-MenuList::-webkit-scrollbar-track,
.creatable-with-search .css-4ljt47-MenuList::-webkit-scrollbar-track {
    @apply w-[4px] rounded-[50px] bg-interface-50;
}
.default-react-select .css-4ljt47-MenuList::-webkit-scrollbar,
.creatable-with-search .css-4ljt47-MenuList::-webkit-scrollbar {
    @apply h-[6px] w-[5px] rounded-[50px] bg-common-white;
}
.default-react-select .css-4ljt47-MenuList::-webkit-scrollbar-thumb,
.creatable-with-search .css-4ljt47-MenuList::-webkit-scrollbar-thumb {
    @apply rounded-[50px] bg-interface-200;
}
.country-flags svg {
    @apply block h-[40px] w-full;
}
.trash-icon-container svg {
    @apply w-[9px] fill-common-white;
}
.default-react-select .css-1okebmr-indicatorSeparator svg,
.default-react-select
    .css-tlfecz-indicatorContainer
    .default-react-select
    .css-tlfecz-indicatorContainer
    svg,
.default-react-select.custom-select
    .css-tlfecz-indicatorContainer
    .default-react-select.custom-select
    .css-tlfecz-indicatorContainer
    svg {
    @apply text-interface-600 !important;
}
.default-react-select.dark .css-14el2xx-placeholder {
    @apply text-interface-200;
}
/* .css-1hb7zxy-IndicatorsContainer {
    @apply hidden !important;
} */
.custom-react-option {
    @apply flex w-full items-center px-5 hover:bg-interface-100;
}
.custom-react-option .inner {
    @apply flex w-full;
}
.custom-react-option .inner .title {
    @apply pr-5 font-themeFont text-[15px] text-common-black;
}
.custom-react-option .inner .description {
    @apply font-themeFont text-sm text-interface-600;
}
.custom-react-option .css-6j8wv5-Input {
    @apply w-full;
}
.default-react-select .custom-react-option.selected-option {
    @apply bg-interface-100;
}
.default-react-select .css-1rhbuit-multiValue,
.default-react-select.dark .css-1rhbuit-multiValue {
    @apply scrollable bg-interface-200;
}
.default-react-select .css-1rhbuit-multiValue .css-xb97g8:hover,
.default-react-select.dark .css-1rhbuit-multiValue .css-xb97g8:hover {
    @apply bg-transparent text-primary-600;
}
.default-react-select .css-1rhbuit-multiValue .css-xb97g8 .css-tj5bde-Svg,
.default-react-select.dark .css-1rhbuit-multiValue .css-xb97g8 .css-tj5bde-Svg,
.default-react-select .css-1rhbuit-multiValue .css-xb97g8 .css-8mmkcg,
.default-react-select.dark .css-1rhbuit-multiValue .css-xb97g8 .css-8mmkcg {
    @apply text-primary-600;
}
/* .default-react-select .css-b62m3t-container .css-g1d714-ValueContainer,
.default-react-select .css-b62m3t-container .css-1hwfws3 {
    @apply scrollable h-[38px] overflow-y-auto;
} */
/* =============
    Customize Ck Editor
=============== */
.customizeCkeditor {
    @apply mb-6;
}
.customizeCkeditor .ck.ck-toolbar.ck-toolbar_grouping,
.customizeCkeditor
    .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    @apply border-interface-200;
}
.customizeCkeditor .ck.ck-toolbar.ck-toolbar_grouping {
    @apply h-11 bg-common-white px-4;
}
.customizeCkeditor .ck.ck-content.ck-editor__editable {
    @apply p-6;
}
.customizeCkeditor .ck.ck-editor__editable_inline {
    @apply min-h-[220px];
}
.customizeCkeditor .ck.ck-editor__editable_inline > :first-child {
    @apply mt-0;
}
.customizeCkeditor .ck.ck-editor__editable_inline p {
    @apply mb-6;
}
.customizeCkeditor .ck.ck-editor__editable_inline h1,
.customizeCkeditor .ck.ck-editor__editable_inline h2,
.customizeCkeditor .ck.ck-editor__editable_inline h3,
.customizeCkeditor .ck.ck-editor__editable_inline h4,
.customizeCkeditor .ck.ck-editor__editable_inline h5,
.customizeCkeditor .ck.ck-editor__editable_inline h6 {
    @apply mb-2;
}
.customizeCkeditor .ck.ck-editor__editable_inline h5 {
    @apply mb-4;
}
.customizeCkeditor .ck.ck-editor__editable_inline h6 {
    @apply mb-1;
}
.customizeCkeditor button.ck.ck-button {
    @apply hover:bg-interface-100 active:bg-interface-100;
}
.customizeCkeditor .ck.ck-editor__editable_inline ul,
.customizeCkeditor .ck.ck-editor__editable_inline ol {
    @apply mb-6;
}
.customizeCkeditor .ck.ck-editor__editable_inline ul li,
.customizeCkeditor .ck.ck-editor__editable_inline ol li {
    @apply mb-3 ml-4 list-outside;
}
.customizeCkeditor .ck.ck-editor__editable_inline ul {
    @apply list-disc;
}
.customizeCkeditor .ck.ck-editor__editable_inline ol {
    @apply list-inside list-decimal;
}
.ck.ck-content.ck-editor__editable.ck-editor__editable_inline.ck-focused {
    @apply border-interface-400 shadow;
}
.customizeCkeditor .ck.ck-editor__editable_inline ul li ul,
.customizeCkeditor .ck.ck-editor__editable_inline ol li ul {
    @apply mt-3 mb-5 pl-6;
}
/* Flight Calendar */
.flight-calender-open .backDrop {
    @apply pointer-events-none fixed  top-0 right-0  z-30 h-full w-[calc(100%-270px)] bg-common-black opacity-60;
}
.flight-calender-open .activityBackDrop {
    @apply right-0 left-auto;
}
.flight-calendar-container {
    @apply fixed left-[261px] z-50 h-screen w-[384px] bg-common-white p-4 shadow ipad:left-0 ipad:z-[99] mobile:w-full;
}
.flight-calendar-container .row {
    @apply flex gap-1;
}
.flight-calendar-container .row-middle {
    @apply items-center;
}
.flight-calendar-container .col {
    @apply flex-grow;
}
.flight-calendar-container .col-center {
    width: calc(100% / 7);
    flex-basis: calc(92% / 7);
    @apply flex-grow-0 justify-center text-center;
}
.flight-calendar-container .col-end {
    @apply justify-end text-right;
}
.calendar {
    @apply relative block w-full pb-4;
}
.calendar .header .icon:first-of-type {
    @apply ml-4;
}
.calendar .header .icon:last-of-type {
    @apply mr-4;
}
.calendar .body .cell {
    @apply relative h-14 translate-x-1 cursor-pointer rounded-lg;
}
.calendar .body .selected {
    @apply bg-primary-500 text-common-white;
}
.calendar .body .cell:last-child {
    @apply border-r-0;
}
.calendar .body .cell .flight-block {
    @apply absolute bottom-0 left-0 top-1 m-auto flex w-full items-center justify-center font-acumin-bold text-base;
}
.calendar .body .cell .flight-block .week-set {
    @apply absolute -top-0.5 text-interface-600;
}
.calendar .body .cell .flight-block .number-set {
    @apply relative top-2;
}
.calendar .body .today .flight-block .week-set {
    @apply text-common-white;
}
.calendar .body .selected .flight-block .week-set {
    @apply text-common-white;
}
.calendar .body .cell .bg {
    @apply opacity-0;
}
.calendar .body .col {
    flex-basis: calc(92% / 7);
    width: calc(100% / 7);
    @apply flex-grow-0;
}
.showFlightDetails .booking-card-container {
    @apply border-0 bg-secondary-100 p-2;
}
.showFlightDetails .flight-detail-section {
    @apply pt-0;
}
.showFlightDetails .booking-detail {
    @apply pr-0;
}
.showFlightDetails .seperator {
    @apply absolute bottom-5 w-full border-b border-dotted border-gray-200;
}
.carousel.carousel-slider .control-dots li.dot {
    @apply mx-2 h-[12px] w-[12px] rounded-full border-2 border-common-white  bg-transparent opacity-100 shadow-none hover:shadow-none focus-visible:shadow-none;
}
.carousel.carousel-slider .control-dots li.dot.selected {
    @apply border-common-white bg-common-white;
}
.carusel-single-slide .carousel.carousel-slider .control-dots li {
    @apply hidden;
}
.carousel.carousel .control-arrow,
.carousel.carousel.carousel-slider .control-arrow {
    background: rgba(0, 0, 0, 0.2);
    @apply top-[47%]   h-[32px] w-[32px]  rounded-full bg-contain opacity-100 before:hidden after:relative after:left-[4px] after:block after:h-4 after:w-4 after:bg-[url('/arrow.svg')] after:content-[''];
}
.carousel.carousel.carousel-slider .control-arrow.control-prev {
    @apply ml-4 after:left-[2px] after:rotate-180;
}
.carousel .control-next.control-arrow {
    @apply mr-4;
}
.documents-preview-slide .carousel.carousel .slide > div > span,
.documents-preview-slide .carousel.carousel .slide img {
    @apply relative h-auto w-full !important;
}
/*
    Next Previous button
*/
td.fc-daygrid-day.fc-day {
    @apply h-[180px] ipad:h-[150px] mobile:h-[140px];
}
.fc.fc-media-screen {
    @apply relative mb-0;
}
.fc.fc-media-screen .fc-header-toolbar .fc-toolbar-chunk button.fc-prev-button,
.fc.fc-media-screen .fc-header-toolbar .fc-toolbar-chunk button.fc-next-button {
    @apply w-[64px] rounded-tl-md rounded-bl-md border-interface-200 bg-interface-50 px-6 shadow-none outline-none focus-within:shadow-none focus:shadow-none focus-visible:shadow-none mobile:w-1/2 mobile:px-1;
}
.fc.fc-media-screen button.fc-today-button {
    @apply h-[38px] w-[64px] rounded-none border border-interface-200 bg-interface-100 capitalize text-interface-600 focus-within:shadow-none focus:border-interface-200 focus:bg-interface-100 focus:text-interface-600 focus:opacity-100 focus:active:shadow-none disabled:bg-primary-600 disabled:text-common-white disabled:opacity-100 mobile:px-3;
}
.fc.fc-media-screen .fc-header-toolbar .fc-toolbar-chunk button.fc-next-button {
    @apply relative mr-[-1.5px] rounded-tl-none rounded-bl-none;
}
.fc.fc-media-screen .fc-header-toolbar .fc-toolbar-chunk button .fc-icon {
    @apply text-interface-600;
}
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-next-button
    .fc-icon {
    @apply -left-[3px];
}
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-prev-button
    .fc-icon {
    @apply right-[3px];
}
.fc.fc-media-screen .fc-header-toolbar .fc-toolbar-chunk button.fc-prev-button {
    @apply rounded-tr-none rounded-br-none;
}
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-prev-button
    span,
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-next-button
    span {
    @apply relative -top-0.5;
}
.fc-toolbar-chunk {
    @apply mobile:mb-1 mobile:w-full mobile:last-of-type:mb-0;
}
.fc.fc-media-screen .fc-header-toolbar .fc-toolbar-chunk .fc-button-group {
    @apply mr-[225px] ipad:mr-0 mobile:w-full;
}
.fc-toolbar-chunk .fc-button-group button {
    @apply font-acumin-bold capitalize;
}
.fc-toolbar-chunk .fc-button-group button:first-child {
    @apply rounded-tl-md rounded-bl-md;
}
.fc-toolbar-chunk .fc-button-group button:last-child {
    @apply rounded-tr-md rounded-br-md;
}
.fc.fc-media-screen .fc-header-toolbar .fc-toolbar-chunk button:last-of-type {
    @apply rounded-tl-none rounded-bl-none rounded-tr-md rounded-br-md;
}
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-dayGridMonth-button,
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-timeGridWeek-button,
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-timeGridDay-button,
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-listWeek-button {
    @apply h-[38px] border-interface-200 bg-interface-100 px-6 pt-1 text-interface-600 focus-within:shadow-none focus:shadow-none focus-visible:shadow-none mobile:px-3;
}
.fc .fc-timegrid-col.fc-day-today {
    @apply bg-interface-100 !important;
}
.fc.fc-media-screen
    .fc-header-toolbar
    .fc-toolbar-chunk
    button.fc-button-active {
    @apply bg-common-white text-common-black;
}
.fc .fc-toolbar-title {
    @apply absolute top-0.5 left-0 ipad:left-0 mobile:top-3;
}
.fc-daygrid-day-top {
    @apply justify-center bg-interface-100 text-common-black;
}
.fc .fc-daygrid-day-events {
    @apply mx-1;
}
a.fc-daygrid-event.fc-daygrid-dot-event.fc-event,
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event {
    @apply mt-1.5;
}
.fc-view-harness {
    @apply h-[calc(100vh-180px)] overflow-hidden rounded-tl-xl rounded-tr-xl lg:h-[calc(100vh-180px)] ipad:h-[calc(100vh-153px)] !important;
}
.fc-timegrid.fc-timeGridDay-view.fc-view .fc-daygrid-event-harness {
    @apply inline-block;
}
.fc-view-harness table thead {
    @apply bg-interface-900;
}
.fc-view-harness.fc-view-harness-active table thead tr th a {
    @apply py-[7px] text-common-white;
}
.fc-timegrid-event-harness.fc-timegrid-event-harness-inset {
    @apply w-full;
}
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end {
    @apply mx-1 mt-1.5;
}
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event,
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start,
a.fc-daygrid-event.fc-daygrid-block-event.fc-event.fc-event-start.fc-event-today,
a.fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-today,
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-today,
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-future,
a.fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-future,
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-end.fc-event-future,
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-future,
a.fc-timegrid-event.fc-v-event.fc-event.fc-event-start.fc-event-future,
a.fc-timegrid-event.fc-v-event.fc-event.fc-event-future,
a.fc-timegrid-event.fc-v-event.fc-event.fc-event-end.fc-event-future {
    @apply mt-1.5 rounded border-calender-active-color bg-calender-active-color py-0.5;
}
a.fc-daygrid-event.fc-event.fc-event-start.fc-event-end,
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-past,
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-end.fc-event-past,
a.fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-past,
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-end,
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end,
a.fc-timegrid-event.fc-v-event.fc-event.fc-event-end {
    @apply rounded border-interface-400 bg-interface-400 p-[4px];
}
/* a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start,
a.fc-timegrid-event.fc-v-event.fc-event.fc-event-start {
    @apply mr-1 mt-1.5 overflow-hidden  rounded  border-r border-calender-active-color bg-calender-active-color py-0.5;
} */
.fc-timeGridWeek-view
    a.fc-timegrid-event.fc-v-event.fc-event.fc-event-start.fc-event-future,
.fc-timeGridWeek-view a.fc-timegrid-event.fc-v-event.fc-event.fc-event-future,
.fc-timeGridWeek-view
    a.fc-timegrid-event.fc-v-event.fc-event.fc-event-end.fc-event-future {
    @apply mt-0 overflow-hidden;
}
.fc-timeGridWeek-view {
    @apply whitespace-nowrap;
}
.fc-timeGridDay-view .calender-name {
    @apply w-full;
}
.fc-timeGridDay-view
    a.fc-timegrid-event.fc-v-event.fc-event.fc-event-start.fc-event-future,
.fc-timeGridDay-view a.fc-timegrid-event.fc-v-event.fc-event.fc-event-future,
.fc-timeGridDay-view
    a.fc-timegrid-event.fc-v-event.fc-event.fc-event-end.fc-event-future {
    @apply mt-0 overflow-hidden;
}
.fc-daygrid .fc-daygrid-body.fc-daygrid-body-unbalanced {
    @apply w-full !important;
}
.fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
    @apply bg-common-white;
}
.fc-dayGridMonth-view .calender-name {
    @apply block whitespace-normal;
}
.flight-content-block {
    @apply -m-[2px] overflow-hidden px-2 text-[13px] text-common-white;
}
.fc-view.fc-list.fc-listWeek-view .flight-content-block {
    @apply overflow-hidden text-common-black;
}
.fc-view.fc-list.fc-listWeek-view .fc-list-event-time {
    @apply hidden;
}
.fc .fc-list-empty {
    @apply rounded-md border-interface-300 bg-interface-100 !important;
}
.fc .fc-toolbar {
    @apply mobile:flex-col;
}
.fc .fc-toolbar.fc-header-toolbar {
    @apply pb-8 ipad:mt-[120px] mobile:mt-[50px] mobile:pb-3;
}
.rc-time-picker {
    @apply absolute top-0 left-0 right-0 h-full w-full text-sm;
}
.rc-time-picker-panel-select {
    @apply w-6/12 text-sm;
}
.rc-time-picker-panel-input {
    @apply text-sm;
}
.rc-time-picker-clear {
    @apply hidden;
}
.rc-time-picker-panel-narrow {
    @apply mb-[-100px] max-w-[160px];
}
.rc-time-picker-panel-inner {
    @apply shadow;
}
.custom-phone-number .PhoneInputCountryIconImg {
    @apply text-primary-600;
}
.custom-phone-number .PhoneInputInternationalIconGlobe {
    @apply hidden;
}
.custom-phone-number.custom-date-time .PhoneInputCountry {
    @apply relative top-3 z-30;
}
.custom-phone-number.custom-date-time .PhoneInputInput {
    @apply pl-12;
}
.custom-phone-number.custom-date-time .PhoneInputCountrySelect,
.custom-phone-number.custom-date-time .PhoneInputCountrySelectArrow {
    @apply hidden;
}
.custom-phone-number.custom-date-time .PhoneInputCountryIcon--border {
    @apply border-none shadow;
}
.custom-date-time.custom-phone-number.currency-field input {
    @apply pl-3;
}
.noScroll .scrollable {
    overflow: initial;
}
/*
    Accordian
*/
.customize-accordion .accordion__item {
    @apply border-b border-interface-200 last-of-type:border-none;
}
.customize-accordion .accordion__button {
    @apply bg-interface-50 px-4 py-3 font-acumin-bold text-sm focus-visible:border-none focus-visible:outline-none;
}
.customize-accordion .accordion__button[aria-expanded='true'] {
    @apply bg-common-white;
}
.customize-accordion .accordion__panel {
    @apply mb-0 bg-common-white px-10 py-0 font-themeFont text-sm text-interface-600;
}
/* Flight details dropdown */
.flight-details-dropdown {
    @apply absolute z-10 mt-8 mb-4 w-[380px] overflow-visible p-3 shadow-lg mobile:w-[325px];
}
/* .flight-details-dropdown .default-react-select .css-b62m3t-container {
    @apply h-auto;
} */
/* .flight-details-dropdown .default-react-select .css-b62m3t-container,
.flight-details-dropdown .default-react-select .css-1s2u09g-control,
.flight-details-dropdown .default-react-select .css-1pahdxg-control,
.flight-details-dropdown
    .default-react-select
    .css-b62m3t-container.css-b62m3t-container {
    @apply h-auto px-0 !important;
}
.flight-details-dropdown .default-react-select .css-26l3qy-menu,
.flight-details-dropdown .default-react-select #react-select-15-listbox {
    @apply relative left-0 right-0 m-0 min-w-[352px] border border-solid border-interface-200 p-0 shadow-none !important;
} */
/* .flight-details-dropdown .default-react-select .css-hxdyr0-control,
.flight-details-dropdown .default-react-select .css-jtl9jd-control {
    @apply mb-2 h-[42px] rounded-md border border-interface-200 pl-8 pr-7;
} */
.notification-scroll .infinite-scroll-component {
    @apply overflow-hidden !important;
}
.flight-details-dropdown .default-react-select.isFormcontrolError .isHasError,
.default-react-select.dark.isFormcontrolError .isHasError {
    @apply border-danger-600 hover:border-danger-600 !important;
}
.documents-preview-slide .slider-wrapper {
    @apply max-h-[500px] overflow-y-auto !important;
}
.documents-preview-slide .slider-wrapper::-webkit-scrollbar-track {
    @apply rounded-[50px] bg-common-white;
}
.documents-preview-slide .slider-wrapper::-webkit-scrollbar {
    @apply h-[6px] w-[4px] rounded-[50px] bg-common-white;
}
.documents-preview-slide .slider-wrapper::-webkit-scrollbar-thumb {
    @apply rounded-[50px] bg-interface-100;
}
.documents-preview-slide .react-pdf__message {
    @apply flex h-[500px] items-center justify-center;
}
.documents-preview-slide .react-pdf__Page__canvas {
    @apply h-full w-full !important;
}
.flight-details-dropdown .css-4ljt47-MenuList {
    max-height: 185px;
}
.react-datepicker-popper.react-datepicker-popper {
    @apply z-50;
}
.modal-scrollable {
    @apply fixed bottom-0 left-0 right-0 mt-5 w-full overflow-hidden rounded-lg bg-common-white p-6;
}
.hasError {
    @apply border-danger-600;
}
/* transactions table  */
.transactions-table td,
.transactions-table th {
    @apply p-2;
}
.transactions-table tr {
    @apply py-3;
}
.notification-scroll {
    max-height: calc(100vh - 110px);
}
.printing .print-hidden {
    @apply hidden;
}
.backdrop {
    @apply fixed top-0 left-0 z-[99] hidden h-full w-full bg-common-black opacity-60;
}
.fc .fc-list-sticky .fc-list-day > * {
    @apply z-[9];
}
.fc-view {
    @apply mobile:overflow-auto;
}
.fc .fc-scrollgrid-liquid,
.fc-scrollgrid-sync-table {
    @apply mobile:min-w-[1100px];
}
.main-modal #scrollableDiv {
    max-height: 290px;
    overflow: auto;
}
.modal-table-container .infinite-scroll-component::-webkit-scrollbar-track {
    @apply rounded-[50px] bg-common-white;
}
.modal-table-container .infinite-scroll-component::-webkit-scrollbar {
    @apply h-[6px] w-[4px] rounded-[50px] bg-common-white;
}
.modal-table-container .infinite-scroll-component::-webkit-scrollbar-thumb {
    @apply rounded-[50px] bg-interface-300;
}
/*.main-modal #scrollableDiv .eZuxfj {
    max-height: none;
}*/
.sc-dmRaPn {
    max-height: none !important;
}
.main-modal .eZuxfj,
.main-modal .sc-dmRaPn,
.main-modal .dkjhBI {
    max-height: none !important;
}
.page-break {
    page-break-after: always;
    page-break-before: always;
    @apply break-before-page break-after-page;
}
.collapse-icon-container {
    @apply h-[12px] w-[16px];
}
.current-invoice-table li .collapse-show {
    @apply w-0 opacity-0 transition-all;
}
.current-invoice-table li:hover .collapse-show,
.current-invoice-table li:focus .collapse-show {
    @apply w-[16px] opacity-100;
}
/* Tooltip */
.tooltip {
    @apply relative grid text-common-black;
}
.tooltip-content-top {
    transform: translateY(-100%);
}
.tooltip-content.tooltip-content-bottom {
    transform: translateY(100%);
    @apply -top-[20px];
}
.tooltip-content {
    @apply invisible absolute -top-[10px] z-[99] w-max min-w-[auto] max-w-[290px] rounded-md bg-interface-700 px-2 py-2 text-sm text-common-white opacity-0 shadow-2xl;
}
.tooltip-content.always-visible {
    @apply visible opacity-100;
}
.tooltip-content::before {
    content: '';
    @apply absolute aspect-square w-[10px] bg-interface-700;
}
.tooltip-content-top::before {
    transform: translateX(-50%) rotate(45deg);
    @apply -bottom-[5px] left-2/4;
}
.tooltip-content-bottom::before {
    transform: translateX(-50%) rotate(-45deg);
    @apply -top-[5px] left-2/4;
}
.tooltip:hover .tooltip-content {
    transition: opacity 0.1s ease-in;
    @apply visible opacity-100;
}
.tooltip .table-label {
    @apply overflow-hidden text-ellipsis whitespace-nowrap;
}
.table-placeholder .tooltip {
    @apply pointer-events-none font-redacted text-interface-200;
}
.table-placeholder .tooltip .tooltip-content {
    @apply hidden;
}
.delete-booking .delete-booking-icon {
    @apply hidden;
}
.delete-booking:hover .delete-booking-icon {
    @apply flex;
}
.custom-textarea-scroll textarea::-webkit-scrollbar-thumb {
    @apply rounded-[50px] bg-interface-400;
}
/* ====
    Select duration picker 
====
*/
.select-duration-timepicker {
    @apply absolute top-[40px] -left-2 max-h-[310px] w-[60px]  rounded bg-common-white shadow;
}
.select-duration-timepicker.minutes {
    @apply left-[-6px] z-[-1];
}
.hideLabel .tagLabel {
    @apply mobile:hidden;
}
.aircraft-async .css-4ljt47-MenuList,
.creatable-with-search .css-4ljt47-MenuList {
    @apply max-h-[200px];
}
.creatable-with-search .css-4ljt47-MenuList > .css-1n7v3ny-option {
    @apply bg-secondary-100;
}
/* .creatable-with-search .css-4ljt47-MenuList > div:hover {
    @apply bg-secondary-100;
} */
.btn-add-collab span .icon-plus {
    @apply text-[10px];
}
.signature-box {
    print-color-adjust: exact;
    @apply bg-interface-100;
}
/* accordians */
.subscription-accordians {
    @apply cursor-pointer;
}
.accordian-header {
    @apply flex border-t border-interface-200 bg-interface-50 px-3 py-2;
}
.subscription-accordians:first-child .accordian-header {
    @apply border-t-0;
}
.preferences textarea {
    @apply resize-none;
}
/* Custom Toggle Button */
.toggle .labels {
    @apply absolute top-[-2px] left-0 ml-3 block h-full w-full text-sm text-common-black transition-all ease-in-out;
}
.toggle .labels::after {
    content: attr(data-off);
    @apply absolute left-[-80px] opacity-100 transition-all ease-in-out;
}
.toggle .labels::before {
    content: attr(data-on);
    @apply absolute  right-[5px] opacity-0 transition-all ease-in-out;
}
.toggle input:checked ~ .labels::after {
    @apply opacity-0;
}
.toggle input:checked ~ .labels::before {
    @apply opacity-100;
}
.flight-sm .passenger-field {
    @apply top-[60px];
}
/* verification code */
.verification-form {
    @apply mb-4;
}
.verification-form .ReactInputVerificationCode__container {
    width: 100%;
}
.verification-form .ReactInputVerificationCode__item {
    line-height: 38px !important;
    @apply h-10 w-14 text-base font-normal text-interface-600;
}
.verification-form .ReactInputVerificationCode__input {
    @apply font-themeFont font-light;
}
.customize-radio .tooltip-content.tooltip-content-bottom {
    transform: initial;
    top: 120%;
}
/* end verification code */
@tailwind utilities;
